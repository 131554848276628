<template>
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <Table :tableBody="tableBody" :tableHeader="tableHeader">
      <div class="mb-3 row">
        <select v-model="type" class="form-select col-2 ms-3 me-5" style="width: 200px !important" @change="getList">
          <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
        </select>
        <span class="checkbox-group ml-5" style="width: 600px !important">
          <label class="form-check-label" v-for="(item, index) in statusList" :key="index">
            <input type="checkbox" v-model="status" :value="item" class="form-check-input" @change="getList">{{item}}
          </label>
        </span>
      </div>
      <div class="mb-5">
        <div class="input-group w-50 float-start">
          <span class="input-group-text fa fa-search"/>
          <input type="text" class="form-control" placeholder="Search by Number" v-model="term" @input="getList">
        </div>
        <router-link class="btn btn-primary float-end" style="width: 142px;" to="/giftcard/new">New Gift Card</router-link>
      </div>
      <div class="clearfix">
      </div>
      <div class="ms-1 my-3">
          Total Value: {{total.toFixed(2)}}
      </div>
    </Table>
</template>

<script>
import axios from 'axios'
import { decode } from "../util.js"

var getStatus = function(item) {
  if(item.status === "New") {
    return { type: "status", class: "badge bg-success", name: item.status }
  } else if(item.status === "Used") {
    return { type: "status", class: "badge bg-secondary", name: item.status }
  } else {
    return { type: "status", class: "badge bg-danger", name: item.status }
  }
}

export default {
  name: 'GiftCard',
  mounted() {
      this.getList()
  },
  data() {
    return {
      tableHeader: ["Retailer", "Card Number", "Pin", "Value | Balance | Used", "Status", "Action"],
      tableBody: [],
      breadcrumbs: [{ name: "Gift Card" }],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger",
      type: "Best Buy",
      status: ["New", "Unknown", "Failed", "Canceled"],
      retailerList: ["Best Buy", "Amazon", "Walmart", "Costco", "Dell", "Microsoft", "Google", "Sony", "Target", "Gamestop", "HP", "Sams Club", "HSN", "Sephora", "eBay", "Staples"],
      statusList: ["New", "Unknown", "Failed", "Canceled", "Used"],
      total: 0,
      term: ""
    }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      axios.delete("/giftcard/" + this.currentId).then(() => {
          this.getList();
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
      this.showModal = false;
    },
    getList() {
      axios.get("/giftcard?type=" + this.type + "&status=" + this.status + "&term=" + this.term).then(response => {
          var openModal = this.openModal;
          this.total = 0;
          this.tableBody = response.data.map(item => {
            this.total += parseFloat((parseFloat(item.value) - parseFloat(item.used || 0)).toFixed(2));
            return {
              type: item.type,
              number: item.number,
              pin: decode(item.pin),
              value: item.value + " | " + (parseFloat(item.value) - parseFloat(item.used || 0)).toFixed(2) + " | " + (item.used || 0),
              status: getStatus(item),
              action: [{ type: "link", link: "/giftcard/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" },
                    { type: "button", click: function() { openModal(item.id) }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" }]
            }
          })
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    }
  }
}
</script>

<style scoped>
.checkbox-group {
  position: relative;
  top: 8px;
}

.checkbox-group > .form-check-label {
  margin-right: 10px;
}

.form-check-input[type=checkbox] {
  margin-right: 2px;
}
</style>